
import axios from 'axios';

export function getAPI(callback, url, additionalHeaders) {
    let headers = { };

    if (additionalHeaders) {
        headers = {...headers, ...additionalHeaders};
    }
    
    axios.get(url, {headers})
        .then(res => callback({ data: res.data, isLoading : false }))
        .catch(err => callback({ error: err, isLoading : false }));
}


export function postAPI(callback, url, body, additionalHeaders) {

    let headers = { };

    if (additionalHeaders) {
        headers = {...headers, ...additionalHeaders};
    }
    
    axios.post(url, body, { headers })
        .then(res => callback({ data: res.data, isLoading : false }))
        .catch(err => callback({ error: err, isLoading : false }));
}


export function putAPI(callback, url, body, additionalHeaders) {

    let headers = { };

    if (additionalHeaders) {
        headers = {...headers, ...additionalHeaders};
    }
    
    axios.put(url, body, { headers })
        .then(res => callback({ data: res.data, isLoading : false }))
        .catch(err => callback({ error: err, isLoading : false }));
}


export function deleteAPI(callback, url, body) {
    
    axios.delete(url, body)
        .then(res => callback({ data: res.data, isLoading : false }))
        .catch(err => callback({ error: err, isLoading : false }));
}