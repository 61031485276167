import React from "react";
import { IntlContext } from "../../Utils/IntlProviderWrapper";


const LanguageSwitch = () => {
    const { switchToLangPT } = React.useContext(IntlContext);
    const { switchToLangEN } = React.useContext(IntlContext);
    const { switchToLangES } = React.useContext(IntlContext);
    const { locale } = React.useContext(IntlContext);    

    
    return (
        <nav className="languages">
            <span onClick={switchToLangPT} className={locale === 'pt' ? "active pt" : "pt"}><span>PT</span></span>
            <span onClick={switchToLangEN} className={locale === 'en' ? "active en" : "en"}><span>EN</span></span>
            <span onClick={switchToLangES} className={locale === 'es' ? "active es" : "es"}><span>ES</span></span>
        </nav>
    );
};


export default LanguageSwitch;