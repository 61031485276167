import React from 'react';
import {Row, Col} from 'reactstrap';
import LanguageSwitch from '../switchLanguage/LanguageSwitch';
import './footer.css';
import Helpers from "../base/Helpers";
import Global from "../base/Global";

let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
console.log(isIOS)

export default class Footer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            generalSettings: Helpers.getGeneralSettings(),
            markers: this.props.markers,
            categories: []
        };

    }

    componentWillMount() {
        this.setCategories();
    }

    /*
    * Get uniq categories/types
    * */
    setCategories() {
        let newCategories = [];
        for (let i = 0; i < this.props.markers.length; i++) {
            if (newCategories.includes(this.props.markers[i].type) || this.props.markers[i].type === "Hotel") {
                continue;
            }
            newCategories.push(this.props.markers[i].type);
        }
        this.setState({categories: newCategories});
    }

    render() {
        const {handleToggleModal, selectedCategory, getRouteMarkers, selectedRoute, toggleFooter,isToShowFooter } = this.props;
        const {generalSettings, categories, markers} = this.state;

        return (
            <div data-show={isToShowFooter ? "True" : "False"} onClick={toggleFooter} className={isIOS ? "is-ios footer-map-container" : "footer-map-container"}>
                <div>
                    <Row>
                        <Col className="languages-container col-sm-6 col-md-3">
                            <h2>{generalSettings["Translates"]["DiscoverLocal"]}</h2>

                            <nav className="main-nav">
                                <a rel="noopener noreferrer" target="_blank"
                                   href={Global.websiteBaseUrlWithoutProxy}>{generalSettings["Translates"]["TheHotel"]}</a>
                                <span onClick={handleToggleModal}>{generalSettings["Translates"]["MyRoute"]}</span>
                            </nav>

                            <LanguageSwitch/>
                        </Col>
                        <Col className={!("SuggestedRoutes" in generalSettings) || generalSettings["SuggestedRoutes"] === null || generalSettings["SuggestedRoutes"] === false ? "explore-map-container col-sm-12 col-lg-9" : "explore-map-container col-sm-6"}>
                            <span className={isToShowFooter ? "to-show" : "to-hide"}>
                                {isToShowFooter ? "close" : "open"}
                            </span>
                            <h2>{generalSettings["Translates"]["ExploreMap"]}</h2>
                            <div>
                                {categories.map((type) =>
                                    <button className={selectedCategory === type ? "active" : ""}
                                            onClick={this.props.getCategoryMarkers.bind(this, type)} key={type}
                                            data-type={type}
                                            style={{backgroundImage: "url(/content/images/Categories/ExploreMap/" + type + ".svg)"}}>
                                        <span>{generalSettings["Translates"][type]}</span>
                                    </button>
                                )}
                                <button className={selectedCategory === "seeAll" ? "active" : ""}
                                        onClick={this.props.getCategoryMarkers.bind(this, "seeAll")} data-type="SeeAll"
                                        style={{backgroundImage: "url(/content/images/Categories/ExploreMap/SeeAll.svg)"}}>
                                    <span>{generalSettings["Translates"]["SeeAll"]}</span>
                                </button>
                            </div>

                        </Col>

                        <SuggestedRoutes
                            markers={markers}
                            getRouteMarkers={getRouteMarkers}
                            selectedRoute={selectedRoute}
                        />
                        
                    </Row>
                </div>
            </div>
        );
    }
}

/*
* Routes
* */
class SuggestedRoutes extends React.PureComponent {
   
    render() {
        const generalSettings = Helpers.getGeneralSettings();
        
        if (!("SuggestedRoutes" in generalSettings) || generalSettings["SuggestedRoutes"] === null || generalSettings["SuggestedRoutes"] === false) {
            return "";
        }

        return (
            <Col className="suggested-routes-container col-sm-6 col-lg-3">
                <h2>{generalSettings["Translates"]["SuggestedRoutes"]}</h2>

                <div>
                    {Helpers.getGeneralSettings()["SuggestedRoutes"].map((route) =>
                        <button className={this.props.selectedRoute === route.title ? "active" : ""} onClick={this.props.getRouteMarkers.bind(this, route.title)} key={route.title}>{route.title}</button>
                    )}
                </div>
                
            </Col>
        );
    }
}