import React from "react";

/* // todo - Do not remove the following comment */
/*global google*/

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    GroundOverlay,
    Marker,
} from "react-google-maps";

const defaultMapOptions = {
    streetViewControl: false,
    scaleControl: false,
    mapTypeControl: false,
    panControl: false,
    zoomControl: false,
    rotateControl: false,
    fullscreenControl: false,
    styles:  [{ elementType: "labels", featureType: "poi", stylers: [{ visibility: "off", }] }],
    minZoom: 0,
    maxZoom: 200,
    gestureHandling: 'greedy'
};

const MapWithGroundOverlay = withScriptjs(withGoogleMap(props =>
    <GoogleMap
        defaultZoom={10}
        defaultOptions={defaultMapOptions}
        defaultCenter={props.center}
        resetBoundsOnResize={true}
        ref={props.onMapMounted}
        options={defaultMapOptions}
    >

        <GroundOverlay
            defaultUrl=""
            defaultBounds={new google.maps.LatLngBounds(
                new google.maps.LatLng(37.206987, -8.649352), // South west corner
                new google.maps.LatLng(37.251774, -8.612891) // North east corner
            )}
            defaultOpacity={0}
        />
        {props.markers.filter(function (item) {
            return item !== undefined;
        }).map(({lat, lng, type, title}) =>
            <Marker key={lat+lng+type}
                    position={{lat: parseFloat(lat), lng: parseFloat(lng)}}
                    icon={{
                        url: window.location.protocol + "//" + window.location.host + "/content/images/Categories/Markers/"+type+".svg",
                        scaledSize: new google.maps.Size(29, 33)
                    }}
                    onClick={props.handleDetail.bind(this, title)}
            />
        )}
        {
            props.hotelMarker === null ? "" :
            <Marker key={props.hotelMarker.lat+props.hotelMarker.lng+props.hotelMarker.type}
                    position={{lat: parseFloat(props.hotelMarker.lat), lng: parseFloat(props.hotelMarker.lng)}}
                    icon={{
                        url: window.location.protocol + "//" + window.location.host + "/content/images/Categories/Markers/"+props.hotelMarker.type+".svg",
                        scaledSize: new google.maps.Size(49, 53)
                    }}
                    onClick={props.handleDetail.bind(this, props.hotelMarker.title)}
            />
        }
    </GoogleMap>
));

export default MapWithGroundOverlay;