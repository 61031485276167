import React from 'react';
import Header from './components/header/Header.js'
import './App.css';
import {getAPI} from "./components/base/API";
import Global from "./components/base/Global";
import Helpers from "./components/base/Helpers";
import Loading from "./components/general/Loading";
import Main from "./components/pages/Main";
import {IntlContext} from "./Utils/IntlProviderWrapper";
export default class App extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: true,
            generalSettingsData: null,
            isToShowRoutesModal: false,
            routeArray: [],
            isToAnimCounter:false,
            isToShowFooter: window.innerWidth >= 400
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.removeRoute = this.removeRoute.bind(this);
        this.clearRoutes = this.clearRoutes.bind(this);
        this.addRouteItem = this.addRouteItem.bind(this);
        this.toggleFooter = this.toggleFooter.bind(this);
    }

    // Get global context
    static contextType = IntlContext; // get context

    async componentDidMount() {
        this.getGeneralSettings();
    }

    // Get general settings from API
    getGeneralSettings() {
        let url = Global.websiteBaseUrlWithoutProxy;
        let language = this.context.locale;

        if(this.context.locale === "pt"){
            language = "pt-pt";
        }
      

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                this.setState({isLoading: false, error: error});
                alert(error);
            }
            if (data) {
                this.setState({generalSettingsData: data, isLoading: false, error: null});
                // Set general settings to localstorage
                Helpers.setGeneralSettings(data);                
            }
        }, url + Global.apiNewPath + Global.apiFunction + "getGeneralSettings" + Global.apiHotel + Global.customURL + Global.apiLang + language );
    }

    // hide/show footer
    toggleFooter() {
        this.setState(prevState => ({
            isToShowFooter: !prevState.isToShowFooter
        }));
    }
    
    // hide/show routes modal
    toggleModal() {
        this.setState(prevState => ({
            isToShowRoutesModal: !prevState.isToShowRoutesModal
        }));
    }
    
    // Remove route
    removeRoute(routeTitle){
        if(this.state.routeArray === []) return;

        let route = [...this.state.routeArray];
        for(let i=0; i< route.length; i++){
            if(route[i].title === routeTitle){
                route.splice(i, 1);
                this.setState({routeArray: route});
                break;
            }
        }
    }
    
    // Clear routes
    clearRoutes() {
        this.setState({routeArray: []});
        this.setState({isToShowRoutesModal: false});
    }
    
    // add a new route item
    addRouteItem(marker){ 
           
        let route = [...this.state.routeArray];
        if(route.some(e => e.reference === marker.reference))
        {
            return;
        }
        
        route.push(marker);

        // if(disableSetState == undefined || !disableSetState)
        // {
            this.setState({routeArray: route});
            this.setState({isToAnimCounter: true});
            
            const _this = this;
            setTimeout(function () {
                _this.setState({isToAnimCounter: false});
            },1000);
        // }       
    }

    render() {
        const {error, isLoading, generalSettingsData, isToShowRoutesModal, routeArray, isToAnimCounter, isToShowFooter} = this.state;

        if (error) {
            return "ERROR";
        }
        else if (isLoading) {
            return (
                <Loading/>
            );
        }
        else{
            return (
                <div className="app-container" data-showfooter={isToShowFooter ? "True" : "False"}>
                    <Header 
                        generalSettingsData={generalSettingsData}
                        handleToggleModal={this.toggleModal}
                        isToShowRoutesModal={isToShowRoutesModal}
                        routeArray={routeArray}
                        removeRoute={this.removeRoute}
                        clearRoutes={this.clearRoutes}
                        isToAnimCounter={isToAnimCounter}
                    />
                    
                    <Main
                        handleToggleModal={this.toggleModal}
                        addRouteItem={this.addRouteItem}
                        toggleFooter={this.toggleFooter}
                        isToShowFooter={isToShowFooter}
                        routeArray={routeArray}
                        googleData={this.googleData}
                        addAllToMyRoute={this.props.addAllToMyRoute}
                    />
                </div>
            )
        }
        
    }
}