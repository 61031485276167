import React from 'react';
import { Row, Col } from 'reactstrap';
import ReactHtmlParser, { } from "react-html-parser";
import Global from "../../base/Global";
import './detail.css';
import Helpers from "../../base/Helpers";
import { getAPI } from "../../base/API";
import StarRatingComponent from 'react-star-rating-component';

export default class Detail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            generalSettings: Helpers.getGeneralSettings(),
            placeId: this.props.markerDetailData == null ? null : this.props.markerDetailData["reference"],
            rating: 0,
            googleData: null,
            mapId: this.mapId
        };

        this.openPlaceIdOnGoogleMaps = this.openPlaceIdOnGoogleMaps.bind(this);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.markerDetailData !== this.props.markerDetailData) {
            if (nextProps.markerDetailData["reference"] === "") {
                this.setState({ placeId: null });
                this.setState({ rating: 0 });
                this.setState({ googleData: null });
            }
            else {
                this.setState({ placeId: nextProps.markerDetailData["reference"] });
                this.getRating(nextProps.markerDetailData["reference"])
            }
        }
    }

    /*
    * Open the location on google maps - new window
    * */
    openPlaceIdOnGoogleMaps() {
        window.open("https://www.google.com/maps/search/?api=1&query=" + this.props.markerDetailData["lat"] + "," + this.props.markerDetailData["lng"] + "&query_place_id=" + this.props.markerDetailData["place_id"]);
    }

    /*
    * Get rating from google maps
    * */
    getRating(placeId) {
        if (placeId === null || placeId === "") {
            return;
        }
       
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                this.setState({ isLoading: false, error: error });
                console.log(error);

                this.setState({ rating: 0 });
                this.setState({ googleData: null });
            }
            if (data) {
                if (data.data.rating !== undefined) {
                    this.setState({ rating: data.data.rating });
                }
                else {
                    this.setState({ rating: 0 });
                }

                if (data.data !== null) {

                    this.setState({ googleData: data.data });
                }
            }

        }, Global.websiteBaseUrlWithoutProxy + Global.apiNewPath + Global.apiFunction + "getGooglePlace" +  Global.apiID + this.props.mapId + Global.apiG_ID + placeId);

    }

    /*
    * If route contains this POI
    * */
    routeContainsThisPoi(arr, value) {
        let found = false;
        

        return found;
    }

    render() {
        const { markerDetailData, isToShowDetail, closeDetail } = this.props;
        const { generalSettings, placeId, rating, googleData } = this.state;

        if (markerDetailData === null) {
            return "";
        }
        
        let poiOnList = this.props.routeArray.some(e => e.reference === markerDetailData.reference);

        return (
            <div className="detail-container" data-visible={isToShowDetail}>
                <div className="col header-close">
                    <div>
                        <span>{ReactHtmlParser(markerDetailData["title"])}</span>
                        <span onClick={closeDetail}>X</span>
                    </div>
                </div>
                <Row>
                    <Col className="col-12 col-sm-6">
                        <div>
                            <h2 className="title">{ReactHtmlParser(markerDetailData["title"])}</h2>
                            <div className="rating">
                                <span>{Math.round(rating * 100) / 100}</span>
                                <StarRatingComponent
                                    name="rate2"
                                    editing={false}
                                    renderStarIcon={() => <span></span>}
                                    starCount={5}
                                    value={Math.round(rating)}
                                    starColor={"#ffb400"}
                                    emptyStarColor={"#000000"}
                                />
                            </div>
                            <div className="description">{ReactHtmlParser(markerDetailData["description"])}</div>
                        </div>
                        <div className="buttons">
                            <button className={placeId == null ? "hidden" : "see-more btn-m-r"} onClick={this.openPlaceIdOnGoogleMaps}>{generalSettings["Translates"]["SeeMore"]}</button>
                            <button disabled={poiOnList} onClick={this.props.addRouteItem.bind(this, markerDetailData)} className="see-more">
                                {generalSettings["Translates"]["AddToMyRoute"]}
                            </button>
                        </div>
                    </Col>

                    <Image
                        imageUrl={markerDetailData["imageUrl"]}
                        imageTitle={markerDetailData["title"]}
                        googleData={googleData}
                    />

                    <Col className="close col-sm-1">
                        <span onClick={closeDetail}>X</span>
                    </Col>
                </Row>
            </div>
        );
    }
}

/*
* Detail Image
* */
class Image extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            photosFromGoogle: []
        };
    }

    componentDidUpdate(nextProps, nextState, nextContext) {
        if (nextProps.googleData !== this.props.googleData) {
            this.setState({ photosFromGoogle: [] });
            this.getImagesFromGoogle();
        }
    }

    /*
    * Get Place photos from google api
    * */
    getImagesFromGoogle() {
        if (this.props.googleData === null || this.props.googleData == null || this.props.googleData.photos == null) return;
        const hasPhoto = this.props.imageUrl === false || this.props.imageUrl === "";
        const numOfPhotos = this.props.googleData.photos.length;
        const numOfPhotosToPrint = numOfPhotos >= (hasPhoto ? 4 : 3) ? (hasPhoto ? 4 : 3) : numOfPhotos;
        let photos = [];

        for (let photo = 0; photo < numOfPhotosToPrint; photo++)
        {
            if(this.props.googleData.photos[photo].url)
            {
                photos.push(this.props.googleData.photos[photo].url);
            }
        }

        this.setState({ photosFromGoogle: photos });
    }

    render() {
        const { imageUrl, imageTitle } = this.props;

        if (imageUrl !== "" && imageUrl !== " " && imageUrl !== null && imageUrl !== undefined && imageUrl !== false) {
            return (
                <Col className="col-12 col-sm-5">
                    <div>
                        <figure style={{ backgroundImage: "url(" + (imageUrl ? imageUrl : ReactHtmlParser(imageUrl)) + ")" }}>
                            {/* <img className="img-fluid" src={Global.websiteBaseUrlWithoutProxy + ReactHtmlParser(imageUrl)} alt={imageTitle}/>*/}
                        </figure>
                        <GoogleImages
                            photosFromGoogle={this.state.photosFromGoogle}
                            imageTitle={imageTitle}
                        />
                    </div>
                </Col>
            );
        }
        else {
            return (
                <Col className="col-12 col-sm-5">
                    <div>
                        <GoogleImages
                            photosFromGoogle={this.state.photosFromGoogle}
                            imageTitle={imageTitle}
                        />
                    </div>
                </Col>
            );
        }
    }
}

/*
* Detail Images from Google
* */
class GoogleImages extends React.PureComponent {
    render() {
        const { photosFromGoogle } = this.props;
        const listItems = photosFromGoogle.map((url) =>
            <figure key={url.toString()} className="col-12 col-sm-6" style={{ backgroundImage: "url(" + url + ")" }}>
                {/* <img className="img-fluid" src={url} alt={imageTitle}/>*/}
            </figure>
        );

        if (photosFromGoogle !== null && photosFromGoogle.length > 0) {
            return (listItems);

        }
        else {
            return "";
        }
    }
}