import React, { PureComponent } from 'react';
import './Loading.css';

class Loading extends PureComponent {
    
    render() {
        return (
            <div className="loading">
                <img src="/content/images/loading.gif" alt="loading"/>
            </div>
        );
    }
}

export default Loading;