import Global from "../base/Global";

const Helpers = {
    getGeneralSettings: function() {
        const gs = localStorage.getItem(Global.localStorage.generalSettings);

        if(gs == null){
            return null;
        }
        return JSON.parse(gs);
    },

    setGeneralSettings: function(data) {
        localStorage.setItem(Global.localStorage.generalSettings, JSON.stringify(data));
    },

    getGoogleDataSettings: function(placeid)
    {
        const settings = sessionStorage.getItem(placeid);
        return settings == null ? settings : JSON.parse(settings);
    },

    setGoogleDataSettings: function(placeid, data)
    {
        sessionStorage.setItem(placeid, JSON.stringify(data));       
    }
};

export default Helpers;