import React from "react";
import { IntlProvider } from "react-intl";

const language = navigator.language.split(/[-_]/)[0];  
const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
    constructor(...args) {
        super(...args);
        
        this.switchToLangEN = () =>
            this.setState({
                locale: 'en'
            });

        this.switchToLangPT = () =>
            this.setState({
                locale: 'pt'
            });

        this.switchToLangES = () =>
            this.setState({
                locale: 'es'
            });

        this.currentProperty = (prop) =>
            this.setState({
                currentProperty : prop
            });
        
        this.state = {
            locale: language,
            switchToLangEN: this.switchToLangEN,
            switchToLangPT: this.switchToLangPT,
            switchToLangES: this.switchToLangES,
            currentProperty: this.currentProperty
        };
    }

    render() {
        const { children } = this.props;
        const { locale, generalSettingsData } = this.state;
        return (
            <Context.Provider value={this.state}>
                <IntlProvider
                    key={locale}
                    locale={locale}
                    defaultLocale="pt"
                    generalSettingsData={generalSettingsData}
                >
                    {children}
                </IntlProvider>
            </Context.Provider>
        );
    }
}

export { IntlProviderWrapper, Context as IntlContext };
