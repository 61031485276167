import React, { PureComponent } from 'react';
import Helpers from "../base/Helpers";
import './screensaver.css';

class ScreenSaver extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            generalSettingsData: Helpers.getGeneralSettings(),
        };
    }
    render() {
        const { generalSettingsData } = this.state;
        return (
            <div className="screen-saver">
                <div className="logo-wrapper">
                    <figure>
                        <img className="white-logo" src={generalSettingsData["LogoWhite"]} alt={generalSettingsData["Translates"]["HotelName"]} /> 
                    </figure>
                </div>
                <div className="animation-wrapper">
                    <figure>
                        <img className="animation" src={generalSettingsData["ScreenSaverSvg"]} alt={generalSettingsData["Translates"]["PlanYourRouteHere"]} /> 
                    </figure>
                </div>
                <div className="text-wrapper">
                    <h3>{generalSettingsData["Translates"]["PlanYourRouteHere"]}</h3>
                    <div className="fake-button">
                        <span>{generalSettingsData["Translates"]["TouchScreenToStart"]}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default ScreenSaver;